import * as React from "react";
import { DataContext } from "../../../context/DataContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button, useTheme } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import basesLoaded from "../../assets/baseballBases/basesLoaded.png";
import first from "../../assets/baseballBases/first.png";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import firstAndSecond from "../../assets/baseballBases/firstAndSecond.png";
import secondAndThird from "../../assets/baseballBases/secondAndThird.png";
import firstAndThird from "../../assets/baseballBases/firstAndThird.png";
import second from "../../assets/baseballBases/second.png";
import third from "../../assets/baseballBases/third.png";
import ReactLoading from "react-loading";

function TodaysBets() {
  const navigate = useNavigate();
  const targetDivRef = React.useRef(null);
  const theme = useTheme();
  const { isLoggedIn, setIsLoggedIn, account, setAccount, isDev } = React.useContext(DataContext);
  const [time, setTime] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [todaysBetsDate, setTodaysBetsDate] = React.useState();
  const [todaysBets, setTodaysBets] = React.useState([]);
  const [selectedBets, setSelectedBets] = React.useState([]);
  const [finalScores, setFinalScores] = React.useState([]);
  const [autoRefreshInterval, setAutoRefreshInterval] = React.useState(25000);
  const [autoRefresh, setAutoRefresh] = React.useState(true);
  const colors = {
    darkRed: "#A43036",
    lightRed: "#FF4F4B",
    yellow: "#FFD301",
    lightGreen: "#7BB662",
    midGreen: "#639754",
    darkGreen: "#006B3D",
    pregame: "#003366",
    lightPurple: "#da8ee7",
    darkPurple: "#bb28bf",
  };
  function convertToMinutes(timeString) {
    const [time, modifier] = timeString.split(" ")[0].split(":");
    let hours = parseInt(time, 10);
    const minutes = parseInt(modifier, 10);

    // Convert to 24-hour format if PM
    if (timeString.includes("PM") && hours < 12) {
      hours += 12;
    } else if (timeString.includes("AM") && hours === 12) {
      // Midnight edge case
      hours = 0;
    }

    // Calculate total minutes since midnight
    return hours * 60 + minutes;
  }

  // React.useEffect(() => {
  //   if (targetDivRef.current) {
  //     targetDivRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [isLoading]);

  const getInningBackgroundColor = (won, bet, index, type) => {
    if (bet.status.type.name === "STATUS_SCHEDULED") {
      return { backgroundColor: colors.pregame, color: "white" };
    } else if (bet.status.type.name === "STATUS_FINAL" && won) {
      return { backgroundColor: colors.darkGreen, color: "white" };
    } else if (bet.status.type.name === "STATUS_FINAL" && !won) {
      return { backgroundColor: colors.darkRed, color: "white" };
    } else if (
      bet.status.type.name === "STATUS_IN_PROGRESS" &&
      index === bet.status.period &&
      type === "away" &&
      bet.status.type.detail.toLowerCase().includes("top")
    ) {
      return { backgroundColor: colors.darkPurple, color: "white" };
    } else if (
      bet.status.type.name === "STATUS_IN_PROGRESS" &&
      index === bet.status.period &&
      type === "home" &&
      bet.status.type.detail.toLowerCase().includes("bot")
    ) {
      return { backgroundColor: colors.darkPurple, color: "white" };
    } else if (
      bet.status.type.name === "STATUS_IN_PROGRESS" &&
      index === bet.status.period &&
      type === "home" &&
      bet.status.type.detail.toLowerCase().includes("top")
    ) {
      return { backgroundColor: "transparent", color: "transparent" };
    } else if (bet.status.type.name === "STATUS_IN_PROGRESS" && index > bet.status.period) {
      return { backgroundColor: "transparent", color: "transparent" };
    } else {
      return { backgroundColor: colors.lightPurple, color: "white" };
    }
  };

  const getTotalRemaining = (score, overUnder, type) => {
    const homeTeamScore = parseInt(score.competitions[0].competitors[0].score);
    const awayTeamScore = parseInt(score.competitions[0].competitors[1].score);
    const remaining = overUnder - (homeTeamScore + awayTeamScore);
    const remainingPointsStr =
      remaining === 0 ? "Tied" : remaining < 0 ? "WON" : `Need ${remaining}`;
    return `Total: ${homeTeamScore + awayTeamScore}  - ${remainingPointsStr}`;
  };

  const getTodaysBets = async () => {
    setIsLoading(true);
    const time = moment().format("h:mm:ss a");
    setTime(time);
    const devURL = `http://localhost:3001/elite-edge-advisors/get-all-bets`;
    const url = `${"https://sheline-art-website-api.herokuapp.com/elite-edge-advisors/get-all-bets"}`;
    const scoresMLB = await getFinalScores(moment().format("YYYYMMDD"));
    const scoresNBA = await getFinalScoresNBA(moment().format("YYYYMMDD"));
    const scoresNHL = await getFinalScoresNHL(moment().format("YYYYMMDD"));
    const scoresNFL = await getFinalScoresNFL(moment().format("YYYYMMDD"));
    const scoresNCAAFootball = await getFinalScoresNCAAFootball(moment().format("YYYYMMDD"));
    const scores = [...scoresMLB, ...scoresNHL, ...scoresNFL, ...scoresNBA, ...scoresNCAAFootball];
    setFinalScores([...scoresMLB, ...scoresNHL, ...scoresNFL, ...scoresNBA, ...scoresNCAAFootball]);
    await fetch(isDev ? devURL : url)
      .then((res) => res.json())
      .then((data) => {
        const { ourBets } = data;
        const filtered = ourBets.map((bet) => {
          return Object.fromEntries(Object.entries(bet).filter(([_, v]) => v != null));
        });
        const allBets = filtered
          .filter(
            (bet) => moment.utc(bet.date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
          )
          .map((unParsedBet) => {
            const keys = Object.keys(unParsedBet).filter((key) => key.includes("bet"));
            const bets = keys.map((key) => unParsedBet[key]);
            return bets.map((bet) => JSON.parse(bet));
          });
        if (allBets.length === 0) {
          setIsLoading(false);
          return;
        }
        console.log("🚀 ~ betsWithScores ~ allBets:", allBets);
        const betsWithScores = allBets[0].map((bet) => {
          const score = scores?.find((x) => x.id === bet.espnGameId);
          return { ...bet, score };
        });
        const inProgress = betsWithScores.filter(
          (bet) =>
            bet.score?.status.type.name === "STATUS_IN_PROGRESS" ||
            bet.score?.status.type.name === "STATUS_HALFTIME" ||
            bet.score?.status.type.name === "STATUS_END_PERIOD" ||
            bet.score?.status.type.name === "STATUS_RAIN_DELAY"
        );
        const scheduled = betsWithScores.filter(
          (bet) => bet.score?.status.type.name === "STATUS_SCHEDULED"
        );
        const finals = betsWithScores.filter(
          (bet) =>
            bet.score?.status.type.name === "STATUS_FINAL" ||
            bet.score?.status.type.name === "STATUS_POSTPONED" ||
            bet.score?.status.type.name === "STATUS_DELAYED"
        );
        allBets.length > 0 &&
          setTodaysBets([
            ...inProgress,
            ...scheduled.sort(
              (a, b) =>
                convertToMinutes(a.details.type.shortDetail.split(" - ")[1]) -
                convertToMinutes(b.details.type.shortDetail.split(" - ")[1])
            ),
            ...finals,
          ]);
        setIsLoading(false);
      });
  };

  const getFinalScores = async (date) => {
    return await fetch(
      `https://site.api.espn.com/apis/site/v2/sports/baseball/mlb/scoreboard?dates=${moment(
        date
      ).format("YYYYMMDD")}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.events;
      });
  };

  const getFinalScoresNHL = async (date) => {
    return await fetch(
      `https://site.api.espn.com/apis/site/v2/sports/hockey/nhl/scoreboard?dates=${moment(
        date
      ).format("YYYYMMDD")}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.events;
      });
  };
  const getFinalScoresNBA = async (date) => {
    return await fetch(
      `https://site.api.espn.com/apis/site/v2/sports/basketball/nba/scoreboard?dates=${moment(
        date
      ).format("YYYYMMDD")}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.events;
      });
  };
  const getFinalScoresNFL = async (date) => {
    return await fetch(
      `https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?dates=${moment(
        date
      ).format("YYYYMMDD")}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.events;
      });
  };
  const getFinalScoresNCAAFootball = async (date) => {
    return await fetch(
      `https://site.api.espn.com/apis/site/v2/sports/football/college-football/scoreboard?groups=80&dates=${moment(
        date
      ).format("YYYYMMDD")}`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.events;
      });
  };

  React.useEffect(() => {
    getTodaysBets();
  }, []);

  React.useEffect(() => {
    if (autoRefresh) {
      getTodaysBets();
      const interval = setInterval(() => {
        getTodaysBets();
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [autoRefresh]);

  React.useEffect(() => {}, [todaysBets]);

  const getBaseImgSrc = (score) => {
    const { onFirst, onSecond, onThird } = score.competitions[0].situation;
    if (onFirst && onSecond && onThird) {
      return (
        <img alt="baseImage" style={{ height: 40, width: 40, marginLeft: 10 }} src={basesLoaded} />
      );
    } else if (onFirst && !onSecond && !onThird) {
      return <img alt="baseImage" style={{ height: 40, width: 40, marginLeft: 10 }} src={first} />;
    } else if (!onFirst && onSecond && !onThird) {
      return <img alt="baseImage" style={{ height: 40, width: 40, marginLeft: 10 }} src={second} />;
    } else if (!onFirst && !onSecond && onThird) {
      return <img alt="baseImage" style={{ height: 40, width: 40, marginLeft: 10 }} src={third} />;
    } else if (onFirst && onSecond && !onThird) {
      return (
        <img
          alt="baseImage"
          style={{ height: 40, width: 40, marginLeft: 10 }}
          src={firstAndSecond}
        />
      );
    } else if (onFirst && !onSecond && onThird) {
      return (
        <img
          alt="baseImage"
          style={{ height: 40, width: 40, marginLeft: 10 }}
          src={firstAndThird}
        />
      );
    } else if (!onFirst && onSecond && onThird) {
      return (
        <img
          alt="baseImage"
          style={{ height: 40, width: 40, marginLeft: 10 }}
          src={secondAndThird}
        />
      );
    }
  };
  const getLinescore = (linescore, bet) => {
    if (bet.score?.competitions[0].format.regulation.periods === 9) {
      if (linescore?.length > 8) {
        return linescore.map((x) => x.value);
      } else if (linescore?.length < 9) {
        const bets = new Array(9 - linescore?.length).fill("");
        bets.unshift(...linescore.map((x) => x.value));
        return bets;
      } else {
        const bets = new Array(9).fill("");
        return bets;
      }
    } else if (bet.score?.competitions[0].format.regulation.periods === 3) {
      if (linescore?.length > 2) {
        return linescore.map((x) => x.value);
      } else if (linescore?.length < 3) {
        const bets = new Array(3 - linescore?.length).fill("-");
        bets.unshift(...linescore.map((x) => x.value));
        return bets;
      } else {
        const bets = new Array(3).fill("-");
        return bets;
      }
    } else if (bet.score?.competitions[0].format.regulation.periods === 4) {
      if (linescore?.length === 4) {
        return linescore.map((x) => x.value);
      } else if (linescore?.length < 4 && linescore?.length > 0) {
        const bets = new Array(4 - linescore?.length).fill("-");
        bets.unshift(...linescore.map((x) => x.value));
        return bets;
      } else {
        const bets = new Array(4).fill("");
        return bets;
      }
    }
  };
  const getColorForBackground = (won, score, tied) => {
    if (score.status.type.name === "STATUS_SCHEDULED") {
      return { backgroundColor: colors.pregame, color: "white" };
    } else if (score.status.type.name === "STATUS_FINAL" && won) {
      return { backgroundColor: colors.darkGreen, color: "white" };
    } else if (score.status.type.name === "STATUS_FINAL" && !won) {
      return { backgroundColor: colors.darkRed, color: "white" };
    } else if (tied) {
      return { backgroundColor: colors.yellow, color: "black" };
    } else if (won) {
      return { backgroundColor: colors.lightGreen, color: "white" };
    } else {
      return { backgroundColor: colors.lightRed, color: "white" };
    }
  };

  const getGameStatus = (score) => {
    const periods = score.competitions[0].format.regulation.periods;
    const comp = score.competitions[0];
    return periods === 9
      ? `${score.status.type.detail} ● ${comp.situation.balls}-${comp.situation.strikes} ● ${comp.outsText}`
      : periods === 3
      ? `${score.status.type.detail}`
      : `${score.status.type.shortDetail} ${
          score?.status.type.name !== "STATUS_HALFTIME"
            ? "○ " + comp.situation.downDistanceText
            : ""
        }`;
  };
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          backgroundColor: "black",
          height: "100vh",
          justifyContent: "center",
          textAlign: "center",
          alignContent: "center",
          alignItems: "center",
          color: "white",
        }}>
        <ReactLoading type={"spin"} color={"green"} height={150} width={150} />
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          backgroundColor: "black",
          height: "100vh",
          alignContent: "center",
          textAlign: "center",
        }}>
        <Grid container spacing={0} style={{}} ref={targetDivRef}>
          {todaysBets?.length > 0 &&
            todaysBets.map((bet, idx) => {
              console.log("🚀 ~ todaysBets.map ~ bet:", bet);
              const periods = Array.from(
                { length: bet.score?.competitions[0].format.regulation.periods },
                (v, k) => k + 1
              );
              const homeTeamID = bet.score?.competitions[0].competitors[0].id;
              const possession =
                bet.score?.competitions[0].situation?.possession === homeTeamID ? "home" : "away";
              const awayTeamDisplayName =
                bet.score?.competitions[0].competitors[1].team.displayName;
              const homeTeamDisplayName =
                bet.score?.competitions[0].competitors[0].team.displayName;
              const awayTeamScore =
                bet.ourBet?.type === "spread" && bet.ourBet?.outcome === awayTeamDisplayName
                  ? parseInt(bet.score?.competitions[0].competitors[1].score) +
                    bet.ourBet?.details.point
                  : parseInt(bet.score?.competitions[0].competitors[1].score);
              const homeTeamScore =
                bet.ourBet?.type === "spread" && bet.ourBet?.outcome === homeTeamDisplayName
                  ? parseInt(bet.score?.competitions[0].competitors[0].score) +
                    bet.ourBet?.details.point
                  : parseInt(bet.score?.competitions[0].competitors[0].score);
              const homeTeamLineScore = getLinescore(
                bet.score?.competitions[0].competitors[0].linescores,
                bet
              );
              const awayTeamLineScore = getLinescore(
                bet.score?.competitions[0].competitors[1].linescores,
                bet
              );
              const shortDetail = bet.details.type.shortDetail;
              const time = shortDetail.split("- ")[1]?.split(" E")[0];
              const momentTime = moment(time, "h:mm a").subtract(1, "hour").format("h:mm a");
              const totalScore = homeTeamScore + awayTeamScore;
              if (bet.ourBet?.type === "overUnder") {
                const tied =
                  (bet.ourBet?.outcome === "over" && totalScore === bet.ourBet?.details.point) ||
                  (bet.ourBet?.outcome === "under" && totalScore === bet.ourBet?.details.point);
                const won =
                  (bet.ourBet?.outcome === "over" && totalScore > bet.ourBet?.details.point) ||
                  (bet.ourBet?.outcome === "under" && totalScore < bet.ourBet?.details.point);
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    onClick={() => {
                      bet.score.links[0].href && window.open(bet.score.links[0].href, "_blank");
                    }}
                    spacing={2}
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      cursor: "pointer",
                      alignItems: "center",
                      border: won
                        ? `1px solid ${getColorForBackground(won, bet.score, tied).backgroundColor}`
                        : `1px solid ${
                            getColorForBackground(won, bet.score, tied).backgroundColor
                          }`,
                      backgroundColor: getColorForBackground(won, bet.score, tied).backgroundColor,
                      padding: "25px 0px",
                      color: getColorForBackground(won, bet.score).color,
                    }}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}>
                      {bet.awayTeam} at {bet.homeTeam}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}>
                      {bet.ourBet?.outcome.toUpperCase()} {bet.ourBet?.details.point}
                    </p>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      {bet.score.status.type.name === "STATUS_SCHEDULED" && (
                        <p style={{ margin: 0, marginTop: 10 }}>{momentTime} CST</p>
                      )}
                      {bet.score.status.type.name === "STATUS_POSTPONED" && (
                        <p style={{ margin: 0, marginTop: 10 }}>POSTPONED</p>
                      )}
                      {bet.score.status.type.name === "STATUS_DELAYED" && (
                        <p style={{ margin: 0, marginTop: 10 }}>DELAYED</p>
                      )}
                      {bet.score.status.type.name !== "STATUS_SCHEDULED" &&
                        bet.score.status.type.name !== "STATUS_FINAL" &&
                        bet.score.status.type.name !== "STATUS_RAIN_DELAY" &&
                        bet.score.status.type.name !== "STATUS_DELAYED" &&
                        bet.score.status.type.name !== "STATUS_POSTPONED" && (
                          <p style={{ margin: 0, marginTop: 10 }}>{getGameStatus(bet.score)}</p>
                        )}
                      <p style={{ margin: 0, marginTop: 10 }}>
                        {getTotalRemaining(
                          bet.score,
                          bet.ourBet?.details.point,
                          bet.ourBet?.outcome
                        )}
                      </p>
                    </div>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <p
                          style={{
                            textAlign: "left",
                            margin: 0,
                            marginTop: 2,
                            color: getColorForBackground(won, bet.score, tied).backgroundColor,
                          }}>
                          INN
                        </p>
                        <p
                          style={{
                            textAlign: "right",
                            margin: 0,
                            marginTop: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}>
                          {!bet.score.links[0].href.includes("nba") &&
                            possession === "away" &&
                            periods.length === 4 && <SportsFootballIcon />}
                          {bet.awayTeamAbbr}
                        </p>
                        <p
                          style={{
                            textAlign: "right",
                            margin: 0,
                            marginTop: 2,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}>
                          {possession === "home" && periods.length === 4 && <SportsFootballIcon />}
                          {bet.homeTeamAbbr}
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <div
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            display: "flex",
                            marginLeft: 5,
                            flexDirection: "row",
                          }}>
                          {periods.map((x) => (
                            <div style={{ marginLeft: 3, marginTop: 0, width: 25 }}>
                              <p style={{ margin: 0, marginTop: 0, marginLeft: 0 }}>{x}</p>
                            </div>
                          ))}
                          <p
                            style={{
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}></p>
                        </div>
                        <div
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            display: "flex",
                            marginLeft: 5,
                            flexDirection: "row",
                          }}>
                          {awayTeamLineScore?.map((x, idx) => (
                            <div
                              style={{
                                border: `1px solid ${getColorForBackground(won, bet.score).color}`,
                                marginLeft: 3,
                                marginTop: 0,
                                width: 25,
                                backgroundColor: getInningBackgroundColor(
                                  won,
                                  bet.score,
                                  idx + 1,
                                  "away"
                                ).backgroundColor,
                              }}>
                              <p
                                style={{
                                  margin: 0,
                                  marginTop: 0,
                                  marginLeft: 0,
                                  color: "white",
                                }}>
                                {x}
                              </p>
                            </div>
                          ))}
                          <p
                            style={{
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}>
                            {bet.score?.competitions[0].competitors[1].score}
                          </p>
                        </div>
                        <div
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            display: "flex",
                            marginLeft: 5,
                            flexDirection: "row",
                          }}>
                          {homeTeamLineScore?.map((x, idx) => (
                            <div
                              style={{
                                border: `1px solid ${getColorForBackground(won, bet.score).color}`,
                                marginLeft: 3,
                                marginTop: 3,
                                width: 25,
                                backgroundColor: getInningBackgroundColor(
                                  won,
                                  bet.score,
                                  idx + 1,
                                  "home"
                                ).backgroundColor,
                              }}>
                              <p
                                style={{
                                  margin: 0,
                                  marginTop: 0,
                                  marginLeft: 0,
                                  // color: getInningBackgroundColor(won, bet.score, idx + 1, "home")
                                  //   .color,
                                }}>
                                {x}
                              </p>
                            </div>
                          ))}
                          <p
                            style={{
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}>
                            {homeTeamScore}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              } else if (bet.ourBet?.type === "spread") {
                const winner =
                  bet.ourBet?.outcome === bet.score?.competitions[0].competitors[1].team.displayName
                    ? "away"
                    : "home";
                const won =
                  winner === "home"
                    ? homeTeamScore >= awayTeamScore
                    : homeTeamScore <= awayTeamScore;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    onClick={() => {
                      bet.score.links[0].href && window.open(bet.score.links[0].href, "_blank");
                    }}
                    md={4}
                    lg={3}
                    spacing={1}
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      display: "flex",
                      flexDirection: "column",
                      cursor: "pointer",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      border: won
                        ? `1px solid ${getColorForBackground(won, bet.score).backgroundColor}`
                        : `1px solid ${getColorForBackground(won, bet.score).backgroundColor}`,
                      backgroundColor: getColorForBackground(won, bet.score).backgroundColor,
                      padding: "25px 0px",
                      color: getColorForBackground(won, bet.score).color,
                    }}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "1.8rem",
                        fontWeight: "bold",
                      }}>
                      {bet.ourBet?.outcome}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}>
                      {bet.ourBet?.details.point > 0 ? "+" : null}
                      {bet.ourBet?.details.point}
                    </p>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      {bet.score.status.type.name === "STATUS_SCHEDULED" && (
                        <p style={{ margin: 0, marginTop: 10 }}>{momentTime} CST</p>
                      )}
                      {bet.score.status.type.name === "STATUS_DELAYED" && (
                        <p style={{ margin: 0, marginTop: 10 }}>DELAYED</p>
                      )}
                      {bet.score.status.type.name === "STATUS_POSTPONED" && (
                        <p style={{ margin: 0, marginTop: 10 }}>POSTPONED</p>
                      )}
                      {bet.score.status.type.name !== "STATUS_SCHEDULED" &&
                        bet.score.status.type.name !== "STATUS_FINAL" &&
                        bet.score.status.type.name !== "STATUS_RAIN_DELAY" &&
                        bet.score.status.type.name !== "STATUS_DELAYED" &&
                        bet.score.status.type.name !== "STATUS_POSTPONED" && (
                          <p style={{ margin: 0, marginTop: 10 }}>{getGameStatus(bet.score)}</p>
                        )}
                      <p style={{ margin: 0, marginTop: 10 }}>{bet.ourBet?.note}</p>
                    </div>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <p
                          style={{
                            textAlign: "left",
                            margin: 0,
                            marginTop: 2,
                            color: getColorForBackground(won, bet.score).backgroundColor,
                          }}>
                          INN
                        </p>
                        <p
                          style={{
                            textAlign: "right",
                            margin: 0,
                            marginTop: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}>
                          {!bet.score.links[0].href.includes("nba") &&
                            possession === "away" &&
                            periods.length === 4 && <SportsFootballIcon />}{" "}
                          {bet.awayTeamAbbr}
                        </p>
                        <p
                          style={{
                            textAlign: "right",
                            margin: 0,
                            marginTop: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}>
                          {possession === "home" && periods.length === 4 && <SportsFootballIcon />}{" "}
                          {bet.homeTeamAbbr}
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <div
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            display: "flex",
                            marginLeft: 5,
                            flexDirection: "row",
                          }}>
                          {periods.map((x) => (
                            <div style={{ marginLeft: 3, marginTop: 0, width: 25 }}>
                              <p style={{ margin: 0, marginTop: 0, marginLeft: 0 }}>{x}</p>
                            </div>
                          ))}
                          <p
                            style={{
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}></p>
                        </div>
                        <div
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            display: "flex",
                            marginLeft: 5,
                            flexDirection: "row",
                          }}>
                          {awayTeamLineScore?.map((x, idx) => (
                            <div
                              style={{
                                border: `1px solid ${getColorForBackground(won, bet.score).color}`,
                                marginLeft: 3,
                                marginTop: 0,
                                width: 25,
                                backgroundColor: getInningBackgroundColor(
                                  won,
                                  bet.score,
                                  idx + 1,
                                  "away"
                                ).backgroundColor,
                              }}>
                              <p
                                style={{
                                  margin: 0,
                                  marginTop: 0,
                                  marginLeft: 0,
                                  color: "white",
                                }}>
                                {x}
                              </p>
                            </div>
                          ))}
                          <p
                            style={{
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}>
                            {awayTeamScore}
                          </p>
                        </div>
                        <div
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            display: "flex",
                            marginLeft: 5,
                            flexDirection: "row",
                          }}>
                          {homeTeamLineScore?.map((x, idx) => (
                            <div
                              style={{
                                border: `1px solid ${getColorForBackground(won, bet.score).color}`,
                                marginLeft: 3,
                                marginTop: 3,
                                width: 25,
                                backgroundColor: getInningBackgroundColor(
                                  won,
                                  bet.score,
                                  idx + 1,
                                  "home"
                                ).backgroundColor,
                              }}>
                              <p
                                style={{
                                  margin: 0,
                                  marginTop: 0,
                                  marginLeft: 0,
                                  color: getInningBackgroundColor(won, bet.score, idx + 1, "home")
                                    .color,
                                }}>
                                {x}
                              </p>
                            </div>
                          ))}
                          <p
                            style={{
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}>
                            {homeTeamScore}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              } else {
                const winner =
                  bet.ourBet?.outcome === bet.score?.competitions[0].competitors[1].team.displayName
                    ? "away"
                    : "home";
                const won =
                  winner === "home" ? homeTeamScore > awayTeamScore : homeTeamScore < awayTeamScore;
                const tied = homeTeamScore === awayTeamScore;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    onClick={() => {
                      bet.score.links[0].href && window.open(bet.score.links[0].href, "_blank");
                    }}
                    lg={3}
                    spacing={2}
                    style={{
                      fontFamily: "'Baloo Bhaijaan', cursive",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      cursor: "pointer",
                      alignContent: "center",
                      alignItems: "center",
                      border: won
                        ? `1px solid ${getColorForBackground(won, bet.score, tied).backgroundColor}`
                        : `1px solid ${
                            getColorForBackground(won, bet.score, tied).backgroundColor
                          }`,
                      backgroundColor: getColorForBackground(won, bet.score, tied).backgroundColor,
                      padding: "25px 0px",
                      color: getColorForBackground(won, bet.score).color,
                    }}>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "1.8rem",
                        fontWeight: "bold",
                      }}>
                      {bet.ourBet?.outcome}
                    </p>
                    <p
                      style={{
                        margin: 0,
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                      }}>
                      ML {bet.ourBet?.details > 0 ? "+" : null}
                      {bet.ourBet?.details}
                    </p>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",
                        display: "flex",
                        flexDirection: "column",
                      }}>
                      {bet.score.status.type.name === "STATUS_SCHEDULED" && (
                        <p style={{ margin: 0, marginTop: 10 }}>{momentTime} CST</p>
                      )}
                      {bet.score.status.type.name === "STATUS_DELAYED" && (
                        <p style={{ margin: 0, marginTop: 10 }}>DELAYED</p>
                      )}
                      {bet.score.status.type.name === "STATUS_POSTPONED" && (
                        <p style={{ margin: 0, marginTop: 10 }}>POSTPONED</p>
                      )}
                      <div
                        style={{
                          margin: 0,
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}>
                        {bet.score.status.type.name !== "STATUS_SCHEDULED" &&
                          bet.score.status.type.name !== "STATUS_FINAL" &&
                          bet.score.status.type.name !== "STATUS_RAIN_DELAY" &&
                          bet.score.status.type.name !== "STATUS_DELAYED" &&
                          bet.score.status.type.name !== "STATUS_POSTPONED" && (
                            <React.Fragment>
                              <p style={{ margin: 0 }}>{getGameStatus(bet.score)}</p>
                              {getBaseImgSrc(bet.score)}
                            </React.Fragment>
                          )}
                      </div>
                      <p style={{ margin: 0, marginTop: 10 }}>{bet.ourBet?.note}</p>
                    </div>
                    <div
                      style={{
                        fontFamily: "'Baloo Bhaijaan', cursive",
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <p
                          style={{
                            textAlign: "left",
                            margin: 0,
                            marginTop: 2,
                            color: getColorForBackground(won, bet.score, tied).backgroundColor,
                          }}>
                          INN
                        </p>
                        <p
                          style={{
                            textAlign: "right",
                            margin: 0,
                            marginTop: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}>
                          {!bet.score.links[0].href.includes("nba") &&
                            possession === "away" &&
                            periods.length === 4 && <SportsFootballIcon />}{" "}
                          {bet.awayTeamAbbr}
                        </p>
                        <p
                          style={{
                            textAlign: "right",
                            margin: 0,
                            marginTop: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}>
                          {possession === "home" && periods.length === 4 && <SportsFootballIcon />}{" "}
                          {bet.homeTeamAbbr}
                        </p>
                      </div>
                      <div
                        style={{
                          fontFamily: "'Baloo Bhaijaan', cursive",
                          display: "flex",
                          flexDirection: "column",
                        }}>
                        <div
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            display: "flex",
                            marginLeft: 5,
                            flexDirection: "row",
                          }}>
                          {periods.map((x) => (
                            <div style={{ marginLeft: 3, marginTop: 0, width: 25 }}>
                              <p style={{ margin: 0, marginTop: 0, marginLeft: 0 }}>{x}</p>
                            </div>
                          ))}
                          <p
                            style={{
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}></p>
                        </div>
                        <div
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            display: "flex",
                            marginLeft: 5,
                            flexDirection: "row",
                          }}>
                          {awayTeamLineScore?.map((x, idx) => (
                            <div
                              style={{
                                border: `1px solid ${getColorForBackground(won, bet.score).color}`,
                                marginLeft: 3,
                                marginTop: 0,
                                width: 25,
                                backgroundColor: getInningBackgroundColor(
                                  won,
                                  bet.score,
                                  idx + 1,
                                  "away"
                                ).backgroundColor,
                              }}>
                              <p
                                style={{
                                  margin: 0,
                                  marginTop: 0,
                                  marginLeft: 0,
                                  color: "white",
                                }}>
                                {x}
                              </p>
                            </div>
                          ))}
                          <p
                            style={{
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}>
                            {bet.score?.competitions[0].competitors[1].score}
                          </p>
                        </div>
                        <div
                          style={{
                            fontFamily: "'Baloo Bhaijaan', cursive",
                            display: "flex",
                            marginLeft: 5,
                            flexDirection: "row",
                          }}>
                          {homeTeamLineScore?.map((x, idx) => (
                            <div
                              style={{
                                border: `1px solid ${getColorForBackground(won, bet.score).color}`,
                                marginLeft: 3,
                                marginTop: 3,
                                width: 25,
                                backgroundColor: getInningBackgroundColor(
                                  won,
                                  bet.score,
                                  idx + 1,
                                  "home"
                                ).backgroundColor,
                              }}>
                              <p
                                style={{
                                  margin: 0,
                                  marginTop: 0,
                                  marginLeft: 0,
                                  color: getInningBackgroundColor(won, bet.score, idx + 1, "home")
                                    .color,
                                }}>
                                {x}
                              </p>
                            </div>
                          ))}
                          <p
                            style={{
                              margin: 0,
                              marginTop: 2,
                              marginLeft: 20,
                              fontWeight: "bold",
                            }}>
                            {bet.score?.competitions[0].competitors[0].score}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              }
            })}
        </Grid>
        {/* <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginTop: 10,
            marginBottom: 10,
          }}> */}
        {/* <Button
            color="secondary"
            variant="contained"
            size="large"
            component="a"
            sx={{ width: 250, fontFamily: "'Baloo Bhaijaan', cursive" }}
            onClick={() => {
              getTodaysBets();
            }}>
            Refresh
          </Button> */}
        {/* <Button
            color={autoRefresh ? "primary" : "secondary"}
            variant="contained"
            size="large"
            component="a"
            sx={{ width: 250, fontFamily: "'Baloo Bhaijaan', cursive" }}
            onClick={() => {
              setAutoRefresh(!autoRefresh);
            }}>
            Turn {!autoRefresh ? "On" : "Off"} Auto Refresh
          </Button> */}
        {/* <Button
            color="secondary"
            variant="contained"
            size="large"
            component="a"
            sx={{ width: 250, fontFamily: "'Baloo Bhaijaan', cursive" }}
            onClick={() => {
              navigate("/previous-bets");
            }}>
            Previous Bets
          </Button> */}
        {/* <Typography
            component="h6"
            variant="h6"
            color="primary"
            style={{ marginTop: 0, fontFamily: "'Baloo Bhaijaan', cursive" }}>
            Refreshed at {time}
          </Typography>
          <Typography
            component="h6"
            variant="h6"
            color="primary"
            style={{ marginTop: 0, fontFamily: "'Baloo Bhaijaan', cursive" }}>
            {moment().format("dddd, MMM Do")}
          </Typography> */}
        {/* </div> */}
      </div>
    );
  }
}

export default TodaysBets;
